<template>
  <div>
    <categories />
  </div>
</template>

<script>
import Categories from '@/components/catalog/categories/Categories.vue'

export default {
  components: { Categories },
  name: 'Category',
}
</script>
