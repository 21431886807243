<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="325"
      height="180"
    >
      <span class="d-none">{{ change }}</span>

      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-5"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Categoría</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn color="primary" icon @click="$emit('dismiss')"
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <label for="" class="ml-4 black--text font-weight-medium">Id</label>
            <v-text-field
              placeholder="Id"
              color="primary"
              rounded
              prepend-inner-icon="fa-file-alt"
              :rules="[rules.required, rules.alfanumeric]"
              required
              outlined
              class="mt-2 mb-n3"
              v-model="categoryData.id"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombre</label
            >
            <v-text-field
              placeholder="Nombre"
              color="primary"
              rounded
              prepend-inner-icon="fa-file-alt"
              :rules="[rules.required, rules.alfanumeric]"
              required
              outlined
              class="mt-2 mb-n3"
              v-model="categoryData.name"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Descripción</label
            >
            <v-text-field
              placeholder="Descripción"
              color="primary"
              rounded
              :rules="[rules.alfanumeric]"
              prepend-inner-icon="fa-file-alt"
              required
              outlined
              class="mt-2 mb-n3 font-quicksand"
              v-model="categoryData.description"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Icono</label
            >
            <v-text-field
              placeholder="Icono"
              color="primary"
              rounded
              prepend-inner-icon="fa-icons"
              required
              outlined
              class="mt-2 mb-n3 font-quicksand"
              v-model="categoryData.icon"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
import DialogEdit from '@/components/DialogEdit.vue'
const CategoryRepository = Repository.get('categories')

export default {
  components: { DialogEdit },
  name: 'FormDialogCategory',
  props: {
    category: {
      type: Object,
      default: { id: '', name: '', description: '', icon: '' },
    },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    categoryData: { id: '', name: '', description: '', icon: '' },
    dialogEdit: false,
    item: {},
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
        if (this.category) this.categoryData = Object.assign({}, this.category)
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'categoría',
              id: this.category.id,
              name: this.category.name,
            }
            this.dialogEdit = true
          }
        }
      })
    },

    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Categoría actualizada con éxito'
          : 'Categoría registrada con éxito',
      }

      this.loading = true

      try {
        this.edit
          ? await CategoryRepository.update(
              this.categoryData,
              this.categoryData.id
            )
          : await CategoryRepository.create(this.categoryData)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
